input, textarea {
    background-color: rgba(255, 255, 255, 0.4);
    color: #fff;
    border: 2px solid #fff;
    font-size: 1.5rem;
    padding: 1rem;
    width: -webkit-fill-available;
    margin: 0.66rem;
}

@media screen and (max-width: 800px) {
    input, textarea {
        font-size: 1rem;
    }
}

input[type="date"]
{
    font-family: 'Inter', sans-serif !important;
    display:block;
    -webkit-appearance: none;
    min-height: 1.2rem;
    background-color: rgba(255, 255, 255, 0.4);
    color: #fff;
    border: 2px solid #fff;
    padding: 0.95rem;
    width: -webkit-fill-available;
    margin: 0.66rem;
    content: 'dd/mm/yyyy';
}

input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
}

input .half {
    width: 50%;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-weight: 100 !important;
}
input::-moz-placeholder, textarea::-moz-placeholder {
    font-weight: 100;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-weight: 100;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif !important;
}
  
  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-family: 'Inter', sans-serif !important;
  }
  
  input:-moz-placeholder, textarea:-moz-placeholder {
      font-family: 'Inter', sans-serif !important;
  }
  
  input::-moz-placeholder, textarea::-moz-placeholder {
      font-family: 'Inter', sans-serif !important;
  }