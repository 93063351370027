
body {
  font-family: 'Inter', sans-serif !important;
  background-color: #002D8A;
}

.home-image {
  width: 100%;
  position: absolute;
  z-index: -1;
}

.home-image img {
  width: inherit;
}

.header {
  text-align: center;
  color: #fff;
  padding-top: 6.5rem;
}

.header .startupLogo {
  height: 6rem;
  margin: 0;
}

.header h2 {
  font-weight: 400 !important;
  font-size: 3rem;
  margin: 0;
  margin-top: -0.4rem;
}

.header p {
  font-size: 1.85rem; 
  margin: 3.3rem 25%;
}

.header h3 {
  margin-top: 5.2rem;
}

 h3 {
  font-size: 2.9rem;
  margin: 0;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.top-header {
    width: 100%;
    position: absolute;      
}

.top-header img {
    width: inherit;
}

.form {
  margin: 3rem 15%;
}

.send {
  text-align: center;
  color: #002D8A;
  background: #fff;
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Inter', sans-serif !important;
  padding: 1rem 3rem !important;
  border-radius: 100px;
  border: none;
  outline: none;
  -webkit-box-shadow: 0px 1rem 0px 0px #001B54; 
  box-shadow: 0px 1rem 0px 0px #001B54;
  cursor: pointer;
  transition: .15s ease-in-out;
}

.send:disabled,
.send[disabled] {
  transform: translate(0, 1rem) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: #bebdbd !important;
}
.double-input {
  display: flex;
}

@media screen and (max-width: 1000px) {
  .double-input {
    display: block;
  }

  .form {
    margin: 3rem 10%;
  }

  .header p {
    margin: 3.3rem 15.2%;
  }
}

@media screen and (max-width: 600px) {
  .double-input {
    display: block;
  }

  .form {
    margin: 3rem 4%;
  }

  .send {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .header p {
    margin: 3.3rem 5%;
  }

  input {
    text-align: center;
  }

  h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .header .startupLogo {
    height: 4.5rem;
  }

  .header h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
    margin: 0 1rem;
  }
}

@media screen and (max-width: 400px) {
  .header .startupLogo {
    height: 3rem;
  }

  .header h2 {
    font-size: 2rem;
  }
}