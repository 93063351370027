.footer {
    padding: 2rem 12rem;
    text-align: center;
    position: relative;
    
    overflow-y: hidden;
    overflow-x: hidden;
}

.ps {
    height: 4.7rem;
    margin-top: 3rem;
}

h2 {
    color: #fff;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.apoiadores {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 0;
    font-weight: 400;
}

.bottom1, .bottom2 {
    position: absolute;
    bottom: -2rem;
}

.bottom2 {
    right: -2rem;
}

.bottom1 {
    left: -2rem;
}

@media screen and (max-width: 1280px) {

    .footer {
        padding: 3rem 7rem;
    }

    .bottom1, .bottom2 {
        bottom: -5rem;
    }
  }

@media screen and (max-width: 1000px) {
    .ps {
        height: 3.5rem;
    }

    .footer {
        padding: 2rem 5rem;
    }

    .bottom1, .bottom2 {
        bottom: -7rem;
    }

    .bottom1 {
        bottom: -10rem;
    }
    .bottom2 {
        right: -4rem;
    }
  }

  @media screen and (max-width: 700px) {
    .apoiadores {
        display: inline-grid;
        text-align: center;
    }

    .apoiadores .wide {
        width: auto !important;
        height: 5rem !important;
        margin: 2rem auto;
    }
    
    .apoiadores .cocriar {
        height: 3rem !important;
    }
    .apoiadores .square {
        height: auto !important;
        width: 10rem !important;
        margin: 0.5rem auto;
    }

    .ps {
        height: 5rem !important;
    }

    h2 {
        font-size: 2.5rem;
        margin: 5rem;
    }

    p {
        font-size: 1.2rem;
        margin: 3rem 0rem 1rem 0rem;
    }

    .bottom1 {
        left: -5rem;
    }
  }

  
  @media screen and (max-width: 610px) {
    .footer {
        padding: 2rem 2rem;
    }
    .apoiadores .wide {
        width: auto !important;
        height: 4rem !important;
        margin: 2rem auto;
    }
    
    .apoiadores .cocriar {
        height: 2rem !important;
    }
    .apoiadores .square {
        height: auto !important;
        width: 8rem !important;
        margin: 0.5rem auto;
    }

    .ps {
        height: 3rem !important;
    }

    h2 {
        font-size: 1.5rem;
        margin: 5rem;
    }

    p {
        font-size: 1rem;
        margin: 3rem 0rem 3rem 0rem;
    }

    .bottom2 {
        right: -6rem;
    }

    .bottom1 {
        bottom: -7rem;
    }
  }

  
  
  @media screen and (max-width: 350px) {

    .apoiadores .wide {
        width: auto !important;
        height: 3rem !important;
        margin: 1rem auto;
    }
    
    .apoiadores .cocriar {
        height: 1.5rem !important;
    }
    .apoiadores .square {
        height: auto !important;
        width: 6rem !important;
        margin: 0rem auto;
    }

    .ps {
        height: 2rem !important;
    }

    h2 {
        font-size: 1rem;
        margin: 5rem;
    }

    p {
        font-size: 0.8rem;
        margin: 3rem 0rem 2rem 0rem;
    }

    .bottom1 {
        bottom: -9rem;
    }
  }